<template>
  <section class="min-h-screen pt-4 bg-gray-200 page-genuses">
    <div
      v-if="loading"
      class="flex flex-col items-center justify-center h-screen"
    >
      <van-loading color="#0094ff">
        加载中...
      </van-loading>
    </div>
    <van-cell-group inset>
      <div
        v-show="!loading"
        ref="ringchart"
        class="ring-chart"
      />
    </van-cell-group>

    <div class="flex flex-row items-center justify-between pb-1 pl-5 pr-4 mt-4">
      <span class="font-normal text-gray-400">
        <i class="fa fa-list" /> 树木数量明细</span>
      <span class="flex flex-row items-center justify-between text-gray-400">
        <span class="mr-1">只看前10名</span>
        <van-switch
          v-model="onlyTop10"
          size="20px"
        /></span>
    </div>
    <van-cell-group
      v-if="data"
      inset
    >
      <template v-for="(row, index) of data">
        <van-cell
          v-if="!onlyTop10 || index < 10"
          :key="`detail-${index}`"
          :title="row.genus"
          :value="`${row.num}棵`"
          is-link
          :to="{
            name: 'public.trees.map',
            query: { genus: row.genusId }
          }"
        />
      </template>
    </van-cell-group>
    <div class="w-full py-2 text-center">
      共有树木：{{ total }}棵
    </div>
    <div class="py-4" />
  </section>
</template>

<script>
import share from '@/mixins/share'
import { mapState } from 'vuex'
import echart from './mixins/echart'

export default {
  name: 'PublicTreesDashboard',
  mixins: [share, echart],
  data() {
    return {
      onlyTop10: true,
      loading: true,
      data: null
    }
  },
  computed: {
    ...mapState('Common/Cloud', ['tcb']),
    ...mapState('Common/Credential', ['userInfo']),
    top10() {
      return (this.data ?? []).slice(0, 10)
    },
    total() {
      return (this.data ?? []).reduce((acc, cur) => {
        acc = acc + cur.num
        return acc
      }, 0)
    }
  },
  async mounted() {
    await Promise.all([this.fetch(), this.prepareWx()])
    let options = {
      title: '树木统计',
      desc: '阳光丽景小区内树木资产统计信息'
    }
    this.updateShareData(options)
  },
  methods: {
    async fetch() {
      this.loading = true
      try {
        const response = await this.tcb.callFunction({
          name: 'trees',
          data: {
            $url: 'dashboard'
          }
        })
        // eslint-disable-next-line no-console
        console.log('函数请求返回', response)
        let { result } = response
        this.$set(this, 'data', result.data)
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err)
        this.$notify({ type: 'danger', message: '暂时无法获取您树木信息' })
        this.data = null
      }
      this.loading = false
      this.$nextTick(() => {
        this.initEChart()
      })
    }
  }
}
</script>

<style lang="scss">
.ring-chart {
  width: 100%;
  height: 250px;
  margin: 0 auto;
}
</style>
