import * as echarts from 'echarts'

export default {
  data() {
    return {
      myechart: null,
      colors: [
        '#5470c6',
        '#91cc75',
        '#fac858',
        '#ee6666',
        '#73c0de',
        '#3ba272',
        '#fc8452',
        '#9a60b4',
        '#ea7ccc',
        '#5470c6'
      ],
      option: {
        title: {
          text: '树木统计图表',
          subtext: '前10名',
          left: 'center',
          top: 10
        },
        tooltip: {
          trigger: 'item',
          formatter: '{b}: {c}棵 ({d}%)'
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: '50%',
            center: ['50%', '60%'],
            avoidLabelOverlap: false,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            data: [],
            animationDelay: function (idx) {
              return idx * 250
            },
            animationEasingUpdate: 'linear'
          }
        ]
      }
    }
  },
  mounted() {
    window.addEventListener('resize', this.resize)
  },
  destroyed() {
    window.removeEventListener('resize', this.resize)
  },
  methods: {
    initEChart() {
      // eslint-disable-next-line no-console
      console.log('initEChart', this.$refs.ringchart)
      this.myechart = echarts.init(this.$refs.ringchart) //初始化一个echarts
      this.option.series[0].data = this.top10.map(item => ({name: item.genus, value: item.num}))
      this.option.color = this.top10.map((item,index) => item.color ?? this.colors[index])
      // eslint-disable-next-line no-console
      console.log('参数', this.option)
      this.myechart.setOption(this.option)
    },
    resize() {
      if (this.myechart) {
        setTimeout(() => {
          this.myechart.resize()
        }, 400)
      }
    }
  }

}
